

































































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
// utilities
import { Chart } from "highcharts-vue";
import Highcharts, { Series } from "highcharts";
import dateHelper from "@/Scripts/utilities/date-helper";

//  types
import { IDataSerie } from "@/types/highcharts/dataSerie";
import { SpeedLogSensorQuality } from "@/types/dataQuality/SpeedLogSensorQuality";

@Component({
  components: {
    Highcharts: Chart,
  },
})
export default class SensorQualityChart extends Vue {
  @Prop({ required: true }) speedLogData!: SpeedLogSensorQuality;
  @Prop({ required: true }) dateRange!: { from: string; to: string };
  @Prop() loading!: boolean;

  chart!: any;
  chartLoaded = false;

  //  @Getters
  get ChartOptions(): any {
    const ctx = this;
    if (!this.chartLoaded) return { chart: ctx.chartSettings, title: { text: null }, series: [] };
    const options = {
      chart: ctx.chartSettings,
      title: { text: null },
      legend: {
        enabled: false,
      },
      yAxis: {
        min: -20,
        max: 20,
        title: {
          text: "",
        },
        labels: {
          format: "{value} %",
          style: { color: "#331714" },
        },
        plotLines: [
          {
            value: 0,
            color: "#CFC9C4",
            width: 2,
            zIndex: 2,
            dashStyle: "dash",
          },
        ],
        plotBands: this.plotBands,
      },
      xAxis: {
        title: {
          text: null,
        },
        type: "datetime",
        minRange: 1,
        labels: {
          y: 35,
        },
        minorTickLength: 0,
        tickLength: 0,
        lineColor: "#E0DCD8",
        max: Date.parse(`${ctx.addTimeGap(this.speedLogData.data[this.speedLogData.data.length - 1].timeStamp)}`),
      },
      plotOptions: {
        area: {
          threshold: 0,
          marker: {
            enabled: false,
          },
        },
        series: {
          showInLegend: true,
          dataLabels: { enabled: false },
          marker: {
            radius: 3,
            fillColor: "#FFFFFF",
            lineWidth: 1,
            lineColor: "#CFC9C4",
            states: {
              select: {
                radius: 4,
              },
            },
          },
        },
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#000",
        borderWidth: 0,
        borderRadius: 13,
        shadow: false,
        style: {
          color: "#fff",
          fontSize: 14,
        },
        formatter: function () {
          const $this: any = this;
          return `
        <div style="line-height: 18px;">
            <span style="margin-bottom: 5px; font-size: 12px;">${dateHelper.getFormatedDateString($this.point.x)}</span> <br />
            <span style="font-size: 12px;">${Number($this.point.y.toFixed(2))}%</span>
        </div>
        `;
        },
      },
      series: this.seriesData,
      credits: { enabled: false },
      exporting: { enabled: false },
    };

    return options;
  }

  get chartSettings(): any {
    if (!this.chartLoaded) return {};
    const ctx = this;
    return {
      type: "line",
      zoomType: "x",
      spacingRight: 0,
      spacingLeft: 0,
      spacingTop: 28,
      // animation: false,
      height: 315,
      style: { fontFamily: "Helvetica Neue" },
      events: {
        selection: function (e: any) {
          const $this: any = this;
          setTimeout(() => {
            ctx.$emit("onZoom", { from: $this.xAxis[0].min, to: $this.xAxis[0].max, resetZoom: Boolean(e.resetSelection) });
            return false;
          }, 100);
        },
      },
    };
  }

  get seriesData(): IDataSerie[] {
    if (!this.speedLogData?.data) return [] as IDataSerie[];
    let series: IDataSerie[] = [];

    series = [this.speedLossPoints];

    return series;
  }

  get speedLossPoints(): IDataSerie {
    return {
      name: "Deviation from baseline",
      type: "line",
      color: "#17b26a",
      zIndex: 1,
      enableMouseTracking: true,
      cropThreshold: 9999,
      marker: {
        symbol: "circle",
      },
      tooltip: {
        outside: true,
        useHTML: true,
        enabled: true,
        borderWidth: 0,
        shadow: false,
        headerFormat: "<small>{point.key}</small><br>",
        pointFormat: "{point.y}",
        valueDecimals: 1,
        valueSuffix: " %",
        xDateFormat: "%d. %b, %Y",
        backgroundColor: "#000",
        style: {
          fontSize: "12px",
          color: "#fff",
        },
      },
      zones: [
        {
          value: -10,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#f6ab5e"],
              [0.1, "#f3a25a"],
              [0.2, "#f09455"],
              [0.3, "#ee8e53"],
              [0.4, "#ec8550"],
              [0.5, "#ea814e"],
              [0.6, "#e7774a"],
              [0.7, "#e67148"],
              [0.8, "#e46b46"],
              [0.9, "#e26343"],
              [1, "#dc503c"],
            ],
          },
        },
        {
          value: -5,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#5db96a"],
              [0.1, "#76bb6a"],
              [0.2, "#9fc069"],
              [0.3, "#aec169"],
              [0.4, "#acc16a"],
              [0.5, "#c5c36a"],
              [0.6, "#d0c66a"],
              [0.7, "#e4c86a"],
              [0.8, "#f7ca6a"],
              [0.9, "#fec668"],
              [1, "#f6ab5e"],
            ],
          },
        },
        {
          value: 5,
          color: "#17b26a",
        },
        {
          value: 10,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#f6ab5e"],
              [0.1, "#fec668"],
              [0.2, "#f7ca6a"],
              [0.3, "#e4c86a"],
              [0.4, "#d0c66a"],
              [0.5, "#c5c36a"],
              [0.6, "#acc16a"],
              [0.7, "#aec169"],
              [0.8, "#9fc069"],
              [0.9, "#76bb6a"],
              [1, "#5db96a"],
            ],
          },
        },
        {
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#dc503c"],
              [0.1, "#e26343"],
              [0.2, "#e46b46"],
              [0.3, "#e67148"],
              [0.4, "#e7774a"],
              [0.5, "#ea814e"],
              [0.6, "#ec8550"],
              [0.7, "#ee8e53"],
              [0.8, "#f09455"],
              [0.9, "#f3a25a"],
              [1, "#f6ab5e"],
            ],
          },
        },
      ],
      data: this.speedLogData?.data.filter(item => item.bias !== null && item.bias !== 0).map(item => [Date.parse(`${item.timeStamp}`), item.bias]) ?? [],
    };
  }

  get plotBands(): any {
    return [
      {
        name: "pb-red",
        color: "#FDECEC",
        from: 10,
        to: 100,
      },
      {
        id: "pb-orange",
        color: "#FFFAEB",
        from: 5,
        to: 10,
      },
      {
        name: "pb-green",
        color: "#ECFDF3",
        from: 5,
        to: -5,
      },
      {
        id: "pb-orange-negative",
        color: "#FFFAEB",
        from: -5,
        to: -10,
      },
      {
        name: "pb-red-negative",
        color: "#FDECEC",
        from: -10,
        to: -100,
      },
    ];
  }

  get noData() {
    return this.seriesData.length === 0;
  }

  // get xAxisMinDate() {

  // }

  //  @Methods
  chartReady(chart: any): void {
    this.chart = chart;
    this.chart.update(this.ChartOptions, true);
    this.chartLoaded = true;
  }

  zoom(selection: { from: string; to: string; resetZoom: boolean }): void {
    this.chart.xAxis[0].zoom(selection.from, selection.to);
    this.chart.redraw();
  }

  addTimeGap(timestamp: string): any {
    const date: any = moment.utc(timestamp).valueOf();
    return moment(date).add(2, "days");
  }
}
