































































































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
// utilities
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import moment from "moment";
import dateHelper from "@/Scripts/utilities/date-helper";

//  types
import { IDataSerie } from "@/types/highcharts/dataSerie";
import { SpeedThroughWater } from "@/types/dataQuality/SpeedThroughWater";

@Component({
  components: {
    Highcharts: Chart,
  },
})
export default class SpeedComparisonChart extends Vue {
  @Prop({ required: true }) derivedSpeedThroughWaterData!: SpeedThroughWater;
  @Prop({ required: true }) speedThroughWaterData!: SpeedThroughWater;
  @Prop({ required: true }) dateRange!: { from: string; to: string };
  @Prop() loading!: boolean;

  chart!: any;
  chartLoaded = false;

  //  @Getters
  get ChartOptions(): any {
    if (!this.chartLoaded || !Highcharts) return {};
    const ctx = this;
    const options = {
      chart: ctx.chartSettings,
      title: { text: "" },
      legend: {
        enabled: false,
        align: "right",
        verticalAlign: "top",
        x: 0,
        y: -30,
        itemStyle: {
          color: "#666666",
          cursor: "pointer",
          fontSize: "14px",
          fontWeight: 400,
        },
      },
      yAxis: {
        min: 0,
        max: 25,
        title: {
          text: "",
        },
        labels: {
          format: "{value} knots",
          style: { color: "#331714" },
        },
      },
      xAxis: {
        title: {
          text: null,
        },
        type: "datetime",
        minRange: 1,
        labels: {
          y: 35,
        },
        minorTickLength: 0,
        tickLength: 4,
        max: Date.parse(`${ctx.addTimeGap(this.speedThroughWaterData.data[this.speedThroughWaterData.data.length - 1].timestamp)}`),
      },
      plotOptions: {
        area: {
          threshold: 0,
          marker: {
            enabled: false,
          },
        },
        series: {
          showInLegend: true,
          dataLabels: { enabled: false },
        },
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#000",
        borderWidth: 0,
        borderRadius: 13,
        shadow: false,
        style: {
          color: "#fff",
          fontSize: 14,
        },
        formatter: function () {
          const $this: any = this;
          return `
                                      <div style="line-height: 18px;">
                                          <span style="margin-bottom: 5px; font-size: 12px;">${dateHelper.getFormatedDateString($this.point.x)}</span> <br />
                                          <span style="font-size: 12px;">${Number($this.point.y.toFixed(2))} knots</span>
                                      </div>
                                      `;
        },
      },
      series: this.seriesData,
      credits: { enabled: false },
      exporting: { enabled: false },
    };

    return options;
  }

  get chartSettings(): any {
    if (!this.chartLoaded) return {};
    const ctx = this;
    return {
      type: "line",
      zoomType: "xy",
      spacingRight: 0,
      spacingLeft: 0,
      spacingTop: 28,
      // animation: false,
      height: 315,
      style: { fontFamily: "Helvetica Neue" },
      events: {
        selection: function (e: any) {
          const $this: any = this;
          setTimeout(() => {
            ctx.$emit("onZoom", { from: $this.xAxis[0].min, to: $this.xAxis[0].max, resetZoom: Boolean(e.resetSelection) });
            return false;
          }, 100);
        },
      },
    };
  }

  get seriesData(): any {
    if (!this.derivedSpeedThroughWaterData?.data || !this.speedThroughWaterData?.data) return [] as IDataSerie[];
    // bugfix < >
    if (this.chart && this.chart.series && this.chart.series.length) {
      /* IMPORTANT: There is also a bug in Highcharts when it has more than 2 series and they are updated dynamically it "loses" correct indexes of the series and displays data in a weird manner so the solution is to clear the series array before it will be updated */
      while (this.chart.series.length) {
        this.chart.series[0].remove();
      }
    }
    // bugfix </>
    const scatterPointsSerie = {
      name: "",
      type: "scatter",
      data: this.derivedSpeedThroughWaterData?.data.filter(item => item.value !== null && item.value !== 0).map(item => [Date.parse(`${item.timestamp}`), item.value]) ?? [],
      stickyTracking: false,
      /* IMPORTANT:  https://www.highcharts.com/forum/viewtopic.php?f=9&t=44589 Turns out scatter points have a bug. When they get updated they can be displayed as line-through-dots with property lineWidth: 2 so here we need to set the lineWidth: 0, otherwise it should be set always through chart.series.forEach loop in serie.options.lineWidth*/
      lineWidth: 0,
      color: "#2970FF",
      marker: {
        radius: 3,
        symbol: "circle",
        color: "#2970FF",
      },
    };

    const scatterPointsSerie2 = {
      name: "",
      type: "scatter",
      data: this.speedThroughWaterData?.data.filter(item => item.value !== null && item.value !== 0).map(item => [Date.parse(`${item.timestamp}`), item.value]) ?? [],
      stickyTracking: false,
      color: "#FDB022",
      /* IMPORTANT: https://www.highcharts.com/forum/viewtopic.php?f=9&t=44589 Turns out scatter points have a bug. When they get updated they can be displayed as line-through-dots with property lineWidth: 2 so here we need to set the lineWidth: 0, otherwise it should be set always through chart.series.forEach loop in serie.options.lineWidth */
      lineWidth: 0,
      marker: {
        radius: 3,
        symbol: "circle",
        color: "#FDB022",
      },
    };

    const series = [scatterPointsSerie, scatterPointsSerie2];

    return series;
  }

  get noData() {
    return this.seriesData.length === 0;
  }

  //  @Methods
  chartReady(chart: any): void {
    this.chart = chart;
    this.chart.update(this.ChartOptions, true);
    this.chartLoaded = true;
  }

  zoom(selection: { from: string; to: string; resetZoom: boolean }): void {
    this.chart.xAxis[0].zoom(selection.from, selection.to);
    this.chart.redraw();
  }

  addTimeGap(timestamp: string): any {
    const date: any = moment.utc(timestamp).valueOf();
    return moment(date).add(2, "days");
  }
}
